import { ExEmptyState, ExIcon } from '@boomi/exosphere';
import { Children, type ReactNode } from 'react';

import './table.css';

const Table = ({
    columnData,
    children,
    setSortBy,
    setSortAsc,
    sortBy,
    sortAsc,
}: {
    columnData: {
        label: string;
        canSort: boolean;
    }[];
    sortBy: string;
    setSortBy: React.Dispatch<React.SetStateAction<string>>;
    sortAsc: boolean;
    setSortAsc: React.Dispatch<React.SetStateAction<boolean>>;
    children: ReactNode;
}) => {
    return (
        <section className="table-wrapper">
            <table data-testid="inboxTable">
                <thead>
                    <tr>
                        {columnData.map(({ label, canSort }) => (
                            <th
                                key={label}
                                onKeyUp={
                                    canSort
                                        ? () => {
                                              setSortBy(label);
                                              setSortAsc(sortBy === label ? !sortAsc : true);
                                          }
                                        : undefined
                                }
                                onClick={
                                    canSort
                                        ? () => {
                                              setSortBy(label);
                                              setSortAsc(sortBy === label ? !sortAsc : true);
                                          }
                                        : undefined
                                }
                            >
                                <div className="th-content">
                                    {label}
                                    {canSort && sortBy === label && (
                                        <ExIcon
                                            className="sort-icon"
                                            icon={`${sortAsc ? 'Up' : 'Down'} caret`}
                                        />
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>{children}</tbody>
            </table>
            {Children.count(children) === 0 && (
                <div className="ex-mt-medium">
                    <ExEmptyState
                        text="Please check your search query or try a different keyword"
                        icon="community"
                        label="No results"
                    />
                </div>
            )}
        </section>
    );
};

export default Table;
