import type { AlertBannerType } from '@boomi/exosphere';
import { ExAlertBanner, AlertBannerVariant } from '@boomi/exosphere';
import { useAlert } from './useAlert';

const Alert = ({
    message,
    onClose,
    alertType,
}: {
    message: string;
    onClose: () => void;
    alertType: AlertBannerType;
}) => (
    <ExAlertBanner open type={alertType} variant={AlertBannerVariant.INLINE} onClose={onClose}>
        {message}
    </ExAlertBanner>
);

const Alerts = () => {
    const { alerts, removeAlert } = useAlert();
    return alerts.map((alert) => (
        <Alert
            key={alert.id}
            alertType={alert.alertType}
            message={alert.alertText}
            onClose={() => removeAlert(alert.id)}
        />
    ));
};

export default Alerts;
