import {
    ButtonFlavor,
    ButtonSize,
    ButtonType,
    CheckboxSize,
    ExButton,
    ExCheckbox,
    ExInput,
    ExTextarea,
} from '@boomi/exosphere';
import { useState } from 'react';
import type { FormResponse, FormValue, FormValues } from '../types/Execute.ts';
import type { ExInputType } from '../types/Task.ts';
import './workflow.css';
import Markdown from 'markdown-to-jsx';
import type { ComponentType } from '../../../designtime-ui/src/types/form.ts';

type Props = {
    form: FormResponse | null;
    onSubmit: (eventId: string, values: FormValues) => Promise<void>;
};

const getInputType = (componentType: ComponentType): ExInputType => {
    switch (componentType) {
        case 'date': {
            return 'date';
        }

        case 'datetime': {
            return 'datetime-local';
        }

        case 'number': {
            return 'number';
        }

        case 'time': {
            return 'time';
        }

        default: {
            return 'text';
        }
    }
};

const WorkflowForm = ({ form, onSubmit }: Props) => {
    const [workflowValues, setWorkflowValues] = useState<Record<string, FormValue>>({});
    const [validation, setValidation] = useState<Record<string, string | null>>({});

    const validateForm = () => {
        const validationResults: Record<string, string | null> = {};

        form?.formComponentResponses.forEach((input) => {
            let validationMessage = '';

            if (input.isRequired && !workflowValues[input.id]) {
                validationMessage = 'This field is required.';
            }

            validationResults[input.id] = validationMessage;
        });

        setValidation(validationResults);

        return Object.values(validationResults).every((value) => !value);
    };

    // When changing the input reset any pre-existing validation,
    // so any validation error indicators are removed from the input.
    const resetInputValidation = (inputId: string) => {
        if (validation[inputId] !== '') {
            setValidation({
                ...validation,
                [inputId]: '',
            });
        }
    };

    const handleSubmit = (eventId: string) => {
        const isValid = validateForm();

        if (isValid) {
            try {
                onSubmit(eventId, workflowValues);
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <div className="form-container">
            <section className="form-inputs">
                <Markdown>{form?.content ?? ''}</Markdown>
                {form?.formComponentResponses
                    .sort((a, b) => a.order - b.order)
                    .map((input) => {
                        switch (input.componentType) {
                            case 'presentation':
                                return <Markdown key={input.id}>{input.value}</Markdown>;

                            case 'longText':
                                return (
                                    <ExTextarea
                                        key={input.id}
                                        placeholder="Enter value here"
                                        disabled={input.disabled}
                                        value={(workflowValues[input.id] as string) ?? input.value}
                                        label={input.label}
                                        required={input.isRequired}
                                        invalid={!!validation[input.id]}
                                        errorMsg={validation[input.id] ?? ''}
                                        onChange={(e) => {
                                            setWorkflowValues({
                                                ...workflowValues,
                                                [input.id]: (e.target as HTMLInputElement).value,
                                            });
                                            resetInputValidation(input.id);
                                        }}
                                    />
                                );

                            case 'checkbox':
                                return (
                                    <ExCheckbox
                                        key={input.id}
                                        size={CheckboxSize.LARGE}
                                        disabled={input.disabled}
                                        checked={
                                            (workflowValues[input.id] as boolean) ||
                                            Boolean(input.value)
                                        }
                                        onChange={(e) => {
                                            setWorkflowValues({
                                                ...workflowValues,
                                                [input.id]: e.detail.checked,
                                            });
                                        }}
                                    >
                                        {input.label}
                                    </ExCheckbox>
                                );

                            case 'text':
                            case 'number':
                            case 'date':
                            case 'datetime':
                            case 'time':
                                return (
                                    <ExInput
                                        key={input.id}
                                        type={getInputType(input.componentType)}
                                        placeholder="Enter value here"
                                        disabled={input.disabled}
                                        value={(workflowValues[input.id] as string) ?? input.value}
                                        label={input.label}
                                        required={input.isRequired}
                                        invalid={!!validation[input.id]}
                                        errorMsg={validation[input.id] ?? ''}
                                        readonly={input.readOnly}
                                        removeMarginBottom
                                        onChange={(e) => {
                                            setWorkflowValues({
                                                ...workflowValues,
                                                [input.id]: (e.target as HTMLInputElement).value,
                                            });
                                            resetInputValidation(input.id);
                                        }}
                                    />
                                );

                            case 'dropdown':
                                return null;

                            case 'multiselect':
                                return null;

                            case 'singlselect':
                                return null;

                            default:
                                return null;
                        }
                    })}
            </section>
            <section className="form-actions">
                {form?.formButtonResponses.map((button) => (
                    <ExButton
                        key={button.id}
                        flavor={ButtonFlavor.BASE}
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.DEFAULT}
                        disabled={button.disabled}
                        onClick={() => handleSubmit(button.id)}
                    >
                        {button.label}
                    </ExButton>
                ))}
            </section>
        </div>
    );
};

export default WorkflowForm;
