import { useParams, useNavigate } from 'react-router-dom';
import { flushSync } from 'react-dom';
import CommentBox from './CommentBox.tsx';
import ActivityList from './ActivityList.tsx';
import {
    ButtonFlavor,
    ButtonSize,
    ButtonType,
    ExButton,
    ExIconButton,
    IconButtonFlavor,
    IconButtonSize,
    IconButtonType,
} from '@boomi/exosphere';
import './task.css';
import {
    addCommentToTask,
    assignTaskToMe,
    getTask,
    submitApproval,
    submitForm,
    submitPresentation,
    unassignTask,
    viewTask,
} from '../utils/api.ts';
import { useEffect, useReducer } from 'react';
import type { CommentApi, WorkflowTaskType } from '../types/Task.ts';
import type { FormValues, WorkflowExecutionResponse } from '../types/Execute.ts';
import WorkflowForm from '../workflow/WorkflowForm.tsx';
import { useUser } from '../auth/UserProvider.tsx';
import { useSocket } from '../../../common/socket/SocketProvider.tsx';

type TaskState = {
    task: WorkflowTaskType | null;
    executionResponse: WorkflowExecutionResponse | null;
};

type TaskAction =
    | { type: 'loadTaskSuccess'; task: WorkflowTaskType }
    | { type: 'loadExecutionResponseSuccess'; executionResponse: WorkflowExecutionResponse }
    | { type: 'taskcommentadded'; comment: CommentApi };

const reducer = (state: TaskState, action: TaskAction) => {
    switch (action.type) {
        case 'loadTaskSuccess':
            return {
                ...state,
                task: action.task,
            };

        case 'loadExecutionResponseSuccess':
            return {
                ...state,
                executionResponse: action.executionResponse,
            };

        case 'taskcommentadded': {
            if (state.task) {
                return {
                    ...state,
                    task: {
                        ...state.task,
                        comments: [...state.task.comments, action.comment],
                    },
                };
            }

            return state;
        }

        default:
            return state;
    }
};

const initialState = {
    task: null,
    executionResponse: null,
};

const fetchTask = async (workspaceId: string, id: string, dispatch: React.Dispatch<TaskAction>) => {
    try {
        const task = await getTask(workspaceId, id);
        dispatch({ type: 'loadTaskSuccess', task });
    } catch (error) {
        console.error(error);
    }
};

const fetchTaskView = async (
    workspaceId: string,
    id: string,
    dispatch: React.Dispatch<TaskAction>,
) => {
    try {
        const executionResponse = await viewTask(workspaceId, id);
        dispatch({ type: 'loadExecutionResponseSuccess', executionResponse });
    } catch (error) {
        console.error(error);
    }
};

const onAssignToMe = async (
    workspaceId: string,
    id: string,
    dispatch: React.Dispatch<TaskAction>,
) => {
    try {
        await assignTaskToMe(workspaceId, id);
        await fetchTask(workspaceId, id, dispatch);
        await fetchTaskView(workspaceId, id, dispatch);
    } catch (error) {
        console.error(error);
    }
};

const onUnassign = async (
    workspaceId: string,
    id: string,
    dispatch: React.Dispatch<TaskAction>,
) => {
    try {
        await unassignTask(workspaceId, id);
        await fetchTask(workspaceId, id, dispatch);
        await fetchTaskView(workspaceId, id, dispatch);
    } catch (error) {
        console.error(error);
    }
};

const Task = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const user = useUser();
    const { workspaceId, id } = useParams();
    const navigate = useNavigate();
    const socket = useSocket();

    const onSubmit = async (eventId: string, values: FormValues) => {
        if (workspaceId && id && state.task && state.executionResponse) {
            try {
                let response: WorkflowExecutionResponse | null = null;

                if (state.executionResponse.workflowExecutionState === 'form') {
                    response = await submitForm(
                        workspaceId,
                        id,
                        {
                            stepId: state.task.currentStep.id,
                            eventId,
                            values,
                        },
                        undefined,
                    );
                } else if (state.executionResponse.workflowExecutionState === 'approval') {
                    response = await submitApproval(
                        workspaceId,
                        id,
                        {
                            eventId,
                        },
                        undefined,
                    );
                } else if (state.executionResponse.workflowExecutionState === 'presentation') {
                    response = await submitPresentation(
                        workspaceId,
                        id,
                        {
                            eventId,
                        },
                        undefined,
                    );
                }

                if (!response) {
                    document.startViewTransition(() => {
                        flushSync(() => {
                            navigate('/inbox');
                        });
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (workspaceId && id) {
            fetchTask(workspaceId, id, dispatch);
            fetchTaskView(workspaceId, id, dispatch);
        }
    }, [workspaceId, id]);

    useEffect(() => {
        const onCommentReceived = (comment: CommentApi) => {
            dispatch({ type: 'taskcommentadded', comment });
        };

        if (socket) {
            socket.on('taskcommentadded', onCommentReceived);

            if (workspaceId && id) {
                socket.invoke('OnTaskOpened', workspaceId, id);
            }
        }

        return () => {
            if (socket) {
                socket.off('taskcommentadded', onCommentReceived);

                if (workspaceId && id) {
                    socket.invoke('OnTaskClosed', workspaceId, id);
                }
            }
        };
    }, [socket, workspaceId, id]);

    if (!workspaceId || !id) {
        return null;
    }

    const onCommentAdded = async (comment: CommentApi) => {
        await addCommentToTask(workspaceId, id, comment);
        dispatch({ type: 'taskcommentadded', comment });
    };

    return (
        <>
            {state.task && (
                <div className="task-layout">
                    <header className="task-header">
                        <ExIconButton
                            className="back-button"
                            flavor={IconButtonFlavor.BRANDED}
                            size={IconButtonSize.DEFAULT}
                            type={IconButtonType.TERTIARY}
                            icon="Left caret"
                            tooltipText="Inbox"
                            hideBrowserTooltip
                            onClick={() => {
                                if (document.startViewTransition) {
                                    document.startViewTransition(() => {
                                        flushSync(() => {
                                            navigate('/inbox');
                                        });
                                    });
                                } else {
                                    flushSync(() => {
                                        navigate('/inbox');
                                    });
                                }
                            }}
                        />
                        <h3 className="title">
                            <span className="title-workflow text-gradient-fill">
                                {state.task.workflowName}
                            </span>
                            <span className="title-separator">/</span>
                            <span className="title-step">{state.task.currentStep.name}</span>
                        </h3>
                        {state.task.assignee?.id !== user?.id ? (
                            <ExButton
                                flavor={ButtonFlavor.BASE}
                                type={ButtonType.SECONDARY}
                                size={ButtonSize.DEFAULT}
                                onClick={() => onAssignToMe(workspaceId, id, dispatch)}
                            >
                                Assign to me
                            </ExButton>
                        ) : (
                            <ExButton
                                flavor={ButtonFlavor.BASE}
                                type={ButtonType.SECONDARY}
                                size={ButtonSize.DEFAULT}
                                onClick={() => onUnassign(workspaceId, id, dispatch)}
                            >
                                Unassign
                            </ExButton>
                        )}
                    </header>

                    {state.executionResponse && (
                        <section className="task-content">
                            <WorkflowForm form={state.executionResponse.form} onSubmit={onSubmit} />
                        </section>
                    )}

                    <section className="task-sidebar">
                        <CommentBox onCommentAdded={onCommentAdded} />
                        <ActivityList
                            comments={state.task.comments}
                            events={state.task.taskHistory}
                        />
                    </section>
                </div>
            )}
        </>
    );
};

export default Task;
