import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { Alert } from '../../designtime-ui/src/types';

const initialState: Alert[] = [];

const alertStateSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        addAlert: (state, action: PayloadAction<Alert>) => {
            return [...state, action.payload];
        },
        removeAlert: (state, action: PayloadAction<string>) => {
            return state.filter((s) => s.id !== action.payload);
        },
    },
});

export default alertStateSlice;
