import {
    type HubConnection,
    HubConnectionBuilder,
    HttpTransportType,
    LogLevel,
} from '@microsoft/signalr';
import { createContext, useContext, useEffect, useState, type ReactNode } from 'react';

type Props = {
    children: ReactNode;
    socketUrl: string;
};

const SocketContext = createContext<HubConnection | null>(null);

const SocketProvider = ({ children, socketUrl }: Props) => {
    const [connection, setConnection] = useState<HubConnection | null>(null);

    useEffect(() => {
        let isCancelled = false;

        const start = async (newConnection: HubConnection) => {
            if (newConnection) {
                await newConnection.start();

                newConnection.onclose(() => {
                    newConnection.start();
                });

                if (isCancelled) {
                    newConnection.stop();
                } else {
                    setConnection(newConnection);
                }
            }
        };

        if (!connection) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(socketUrl, {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                })
                .configureLogging(LogLevel.Trace)
                .build();

            start(newConnection);
        }

        return () => {
            isCancelled = true;

            if (connection) {
                connection.stop();
                setConnection(null);
            }
        };
    }, [connection, socketUrl]);

    return <SocketContext.Provider value={connection}>{children}</SocketContext.Provider>;
};

const useSocket = () => {
    return useContext(SocketContext);
};

export { useSocket, SocketProvider };
