import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../designtime-ui/src/state/store';
import type { WorkflowError } from '../../designtime-ui/src/types';

export const useAlert = () => {
    const alerts = useAppSelector((state) => state.alert);
    const dispatch = useAppDispatch();

    const onSuccess = useCallback(
        (text: string) => {
            dispatch({
                type: 'alert/addAlert',
                payload: { id: crypto.randomUUID(), alertType: 'Success', alertText: text },
            });
        },
        [dispatch],
    );

    const onError = useCallback(
        (error: WorkflowError) => {
            if (error.name === 'AbortError') {
                return;
            }

            dispatch({
                type: 'alert/addAlert',
                payload: {
                    id: crypto.randomUUID(),
                    alertType: 'Error',
                    alertText: error.detail ?? error.title,
                },
            });
        },
        [dispatch],
    );

    const removeAlert = useCallback(
        (alertId: string) => {
            dispatch({ type: 'alert/removeAlert', payload: alertId });
        },
        [dispatch],
    );

    return {
        onSuccess,
        onError,
        removeAlert,
        alerts,
    };
};
