import { createContext, useContext, type ReactNode, useState, useEffect } from 'react';
import { type RuntimeUserAPI, getUser } from '../utils/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface Props {
    children: ReactNode;
    redirect: string;
}

const Context = createContext<RuntimeUserAPI | null>(null);

const UserProvider = ({ redirect, children }: Props) => {
    const [user, setUser] = useState<RuntimeUserAPI | null>(null);
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            let user: RuntimeUserAPI | null = null;
            try {
                user = await getUser();
                setUser(user);
            } catch (error) {
                // TODO handle errors
                console.log(error);
            } finally {
                if (!user) {
                    navigate(`${redirect}?${searchParams.toString()}`);
                }
            }
        };

        fetchUser();
    }, [navigate, redirect, searchParams]);

    return user && <Context.Provider value={user}>{children}</Context.Provider>;
};

const useUser = (): RuntimeUserAPI => {
    const user = useContext(Context);
    if (user === null) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return user;
};

export { UserProvider, useUser };
