import { Outlet } from 'react-router-dom';
import '@boomi/exosphere/dist/styles.css';
import './app.css';
import Alerts from '../../../common/alerts/Alerts';

function App() {
    return (
        <main className="app">
            <Alerts />
            <Outlet />
        </main>
    );
}

export default App;
