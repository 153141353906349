import type {
    GetTasksQueryParamsType,
    CommentApi,
    WorkflowTaskListItem,
    WorkflowTaskType,
} from '../types/Task.ts';
import type { PagedList } from '../../../designtime-ui/src/types/api.ts';
import type {
    ApprovalRequest,
    FormRequest,
    PresentationRequest,
    WorkflowExecutionResponse,
} from '../types/Execute.ts';
import { fetchAndParse, fetchExpectingEmptyResponse } from '../../../common/api/api.ts';

export interface RuntimeUserAPI {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    organizationId: string;
    createdAt: string;
    updatedAt: string;
    groups: null;
    roles: null;
    authenticationType: string | null;
    isAuthenticated: boolean;
    name: string | null;
}

export const getUser = async () =>
    fetchAndParse<RuntimeUserAPI>('/api/runtime/user', 'get', undefined);

export const logIn = async (email: string, password: string) =>
    fetchAndParse('/api/runtime/login/basic', 'post', undefined, {
        Email: email,
        Password: password,
    });

export const getLoginURL = async (email: string) =>
    fetchAndParse(`/api/runtime/login/${email}`, 'get', undefined);

export const getTasks = (workspaceId: string, qp: GetTasksQueryParamsType) => {
    const queryParams = `?${new URLSearchParams(qp).toString()}`;
    return fetchAndParse<PagedList<WorkflowTaskListItem>>(
        `/api/runtime/${workspaceId}/tasks${queryParams}`,
        'get',
        undefined,
    );
};

export const getTask = (workspaceId: string, taskId: string) =>
    fetchAndParse<WorkflowTaskType>(`/api/runtime/${workspaceId}/task/${taskId}`, 'get', undefined);

export const assignTaskToMe = async (workspaceId: string, id: string) =>
    fetchAndParse(`/api/runtime/${workspaceId}/task/${id}/assigntome`, 'put', undefined);

export const unassignTask = async (workspaceId: string, id: string) =>
    fetchAndParse(`/api/runtime/${workspaceId}/task/${id}/unassign`, 'put', undefined);

export const addCommentToTask = async (
    workspaceId: string | undefined,
    id: string,
    comment: CommentApi,
) => fetchAndParse(`/api/runtime/${workspaceId}/task/${id}/addcomment`, 'post', undefined, comment);

export const initializeWorkflow = async (
    workspaceId: string,
    workflowId: string,
    workflowVersionId: number,
) =>
    await fetchAndParse<WorkflowExecutionResponse>(
        `/api/runtime/${workspaceId}/${workflowId}/initialize/${workflowVersionId}`,
        'get',
        undefined,
    );

export const triggerFromForm = async (
    workspaceId: string,
    workflowId: string,
    version: number,
    formRequest: FormRequest,
    signal: AbortSignal | undefined,
) =>
    await fetchExpectingEmptyResponse(
        `/api/runtime/${workspaceId}/${workflowId}/trigger/${version}/form`,
        'post',
        signal,
        formRequest,
    );

export const viewTask = async (workspaceId: string, taskId: string) =>
    await fetchAndParse<WorkflowExecutionResponse>(
        `/api/runtime/${workspaceId}/task/${taskId}/view`,
        'get',
        undefined,
    );

export const submitForm = async (
    workspaceId: string,
    taskId: string,
    formRequest: FormRequest,
    signal: AbortSignal | undefined,
) =>
    await fetchAndParse<WorkflowExecutionResponse>(
        `/api/runtime/${workspaceId}/task/${taskId}/form`,
        'post',
        signal,
        formRequest,
    );

export const submitApproval = async (
    workspaceId: string,
    taskId: string,
    approvalRequest: ApprovalRequest,
    signal: AbortSignal | undefined,
) =>
    await fetchAndParse<WorkflowExecutionResponse>(
        `/api/runtime/${workspaceId}/task/${taskId}/approval`,
        'post',
        signal,
        approvalRequest,
    );

export const submitPresentation = async (
    workspaceId: string,
    taskId: string,
    presentationRequest: PresentationRequest,
    signal: AbortSignal | undefined,
) =>
    await fetchAndParse<WorkflowExecutionResponse>(
        `/api/runtime/${workspaceId}/task/${taskId}/presentation`,
        'post',
        signal,
        presentationRequest,
    );
