import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './layout/App.tsx';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import Login from './login/Login.tsx';
import Inbox from './inbox/Inbox.tsx';
import { UserProvider } from './auth/UserProvider.tsx';
import { InboxProvider } from './inbox/InboxProvider.tsx';
import Task from './task/Task.tsx';
import { Provider } from 'react-redux';
import { store } from './state/store.ts';
import { SocketProvider } from '../../common/socket/SocketProvider.tsx';

const router = createBrowserRouter([
    {
        path: '/inbox',
        element: <App />,
        children: [
            {
                path: 'login',
                element: <Login redirect="/inbox" />,
            },
            {
                element: (
                    <UserProvider redirect="/inbox/login">
                        <SocketProvider socketUrl="/realtime/inbox">
                            <InboxProvider>
                                <Outlet />
                            </InboxProvider>
                        </SocketProvider>
                    </UserProvider>
                ),
                children: [
                    {
                        path: '',
                        element: <Inbox />,
                    },
                    {
                        path: 'task/:workspaceId/:id',
                        element: <Task />,
                        errorElement: (
                            <div>
                                <h1>404</h1>
                            </div>
                        ),
                    },
                ],
            },
        ],
    },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>,
);
