import { memo } from 'react';
import { ExEmptyState, ExIcon } from '@boomi/exosphere';
import type { CommentApi, TaskHistoryAPI, TaskHistoryType } from '../types/Task.ts';

import './activity-list.css';

interface Props {
    comments: CommentApi[];
    events: TaskHistoryAPI[];
}

const isEventHistory = (item: CommentApi | TaskHistoryAPI): item is TaskHistoryAPI => {
    if (Object.hasOwn(item, 'taskHistoryType')) {
        return true;
    }
    return false;
};

const getApprovalLabel = (type: TaskHistoryType): string => {
    switch (type) {
        case 'approvalApproved':
            return 'Approved';
        case 'approvalDenied':
            return 'Denied';
        case 'event':
            return 'Form Submitted';
        default:
            return '';
    }
};

const ActivityList = memo(({ comments, events }: Props) => {
    const timeline: (CommentApi | TaskHistoryAPI)[] = [...comments, ...events];

    return (
        <div className="activity-section">
            <h4>Activity</h4>
            <div className="activity-list-scroller">
                <ul className="activity-list">
                    {timeline.length > 0 ? (
                        timeline
                            .sort(
                                (a, b) =>
                                    new Date(b.createdAt).getTime() -
                                    new Date(a.createdAt).getTime(),
                            )
                            .map((item) => {
                                if (isEventHistory(item)) {
                                    const eventIcon =
                                        item.taskHistoryType === 'event'
                                            ? 'document'
                                            : item.taskHistoryType === 'approvalApproved'
                                              ? 'Success'
                                              : 'Disabled';

                                    return (
                                        <li key={item.id} className="activity event">
                                            <ExIcon icon={eventIcon} className="activity-icon" />
                                            <strong className="activity-title">
                                                {getApprovalLabel(item.taskHistoryType)}
                                            </strong>{' '}
                                            <div className="activity-content">{item.message}</div>
                                            <div className="activity-footer">
                                                {new Date(item.createdAt).toLocaleString()}
                                            </div>
                                        </li>
                                    );
                                }

                                return (
                                    <li key={item.id} className="activity comment">
                                        <ExIcon icon="person-circle" className="activity-icon" />
                                        <strong className="activity-title">{`${item.runtimeUser?.firstName} ${item.runtimeUser?.lastName}`}</strong>{' '}
                                        <div className="activity-content">{item.content}</div>
                                        <div className="activity-footer">
                                            {new Date(item.createdAt).toLocaleString()}
                                        </div>
                                    </li>
                                );
                            })
                    ) : (
                        <ExEmptyState text="" icon="community" label="No activity" />
                    )}
                </ul>
            </div>
        </div>
    );
});

export default ActivityList;
