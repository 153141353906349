import { useState } from 'react';
import './login.css';
import {
    AlertBannerType,
    ExAlertBanner,
    ExButton,
    ExCard,
    ExInput,
    SearchSize,
} from '@boomi/exosphere';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getLoginURL, logIn } from '../utils/api';

interface Props {
    redirect: string;
}

const Login = ({ redirect }: Props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const onLogIn = async () => {
        try {
            await logIn(email, password);
        } catch (error) {
            setErrorMessage((error as Error).message);
            return;
        }

        navigate(`${redirect}?${searchParams.toString()}`);
    };

    const onNext = async () => {
        let url: unknown = null;

        try {
            url = await getLoginURL(email);
        } catch (error) {
            setErrorMessage((error as Error).message);
            return;
        }

        if (url && !url.toString().includes('inbox/login')) {
            // go to auth login url (unless we are already there - basic auth)
            window.location.href = url as string;
        } else {
            // show password field for basic auth
            setEmailSubmitted(true);
        }
    };

    return (
        <div className="login-wrapper">
            <ExCard className="login-card">
                {!emailSubmitted && (
                    <ExInput
                        type="text" // "email" shows an ugly error message while typing
                        label="Email"
                        name="email"
                        inputmode="email"
                        size={SearchSize.MEDIUM}
                        onInput={(e: CustomEvent) => setEmail((e.target as HTMLInputElement).value)}
                    />
                )}
                {emailSubmitted && (
                    <ExInput
                        type="password"
                        name="password"
                        label="Password"
                        size={SearchSize.MEDIUM}
                        onInput={(e: CustomEvent) =>
                            setPassword((e.target as HTMLInputElement).value)
                        }
                    />
                )}
                {errorMessage && (
                    <>
                        <ExAlertBanner open type={AlertBannerType.ERROR}>
                            {errorMessage}
                        </ExAlertBanner>
                        <br />
                    </>
                )}
                {emailSubmitted ? (
                    <ExButton onClick={onLogIn}>Log In</ExButton>
                ) : (
                    <ExButton onClick={onNext}>Next</ExButton>
                )}
            </ExCard>
        </div>
    );
};

export default Login;
