import { configureStore } from '@reduxjs/toolkit';
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import alertSlice from '../../../common/alerts/alert';

export const configureStoreWithPreloadedState = (preloadedState?: unknown) => {
    return configureStore({
        reducer: {
            alert: alertSlice.reducer,
        },
        preloadedState,
        devTools: true,
    });
};

export const store = configureStoreWithPreloadedState();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
