import { useCallback, useEffect, useRef, useState } from 'react';
import type { SortAPI, PagingAPI, PagedList, SortDirection } from './types';
import { DEFAULT_TABLE_PAGE_SIZE } from '../constants';

const send = async (
    url: string,
    method: 'get' | 'put' | 'post' | 'delete',
    body?: unknown,
    signal?: AbortSignal | null | undefined,
) => {
    const response = await fetch(`${window.origin}${url}`, {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: body ? JSON.stringify(body) : undefined,
        signal,
    });

    return response;
};

export const fetchAndParse = async <T>(
    url: string,
    method: 'get' | 'put' | 'post' | 'delete',
    signal?: AbortSignal | null | undefined,
    body?: unknown,
) => {
    const response = await send(url, method, body, signal);
    let parsedResponse: unknown = null;

    try {
        parsedResponse = await response.json();
    } catch {
        // do nothing
    }

    if (!response.ok) {
        if (response.status === 400) {
            throw new Error('Validation error', {
                cause: parsedResponse,
            });
        }

        throw parsedResponse;
    }

    return parsedResponse as T;
};

export const fetchExpectingEmptyResponse = async (
    url: string,
    method: 'get' | 'put' | 'post' | 'delete',
    signal: AbortSignal | null | undefined,
    body?: unknown,
) => {
    const response = await send(url, method, body, signal);

    if (!response.ok) {
        throw await response.json();
    }

    return;
};

export const useFetchAbort = () => {
    const abortControllerRef: React.MutableRefObject<AbortController> = useRef(
        new AbortController(),
    );

    useEffect(() => {
        if (abortControllerRef.current.signal.aborted) {
            abortControllerRef.current = new AbortController();
        }

        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    return abortControllerRef;
};

export type FetchPagedList<T, P = void> = (
    sort: SortAPI<T> | null,
    paging: PagingAPI | null,
    searchQuery: string | null,
    abortSignal: AbortSignal,
    params: P,
) => Promise<PagedList<T>>;

export const useFetchPagedList = <T, P = void>(
    fetchPagedList: FetchPagedList<T, P>,
    items: T[] | null,
    setItems: (items: T[] | null) => void,
    abortSignal: AbortSignal,
) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>();
    const [pageSize, setPageSize] = useState<number>(DEFAULT_TABLE_PAGE_SIZE);

    const onFetchItems = useCallback(
        async (
            page: number,
            sortBy: keyof T,
            direction: SortDirection,
            searchQuery: string | null,
            pageSize: number,
            params: P,
        ) => {
            try {
                setIsLoading(true);
                const response = await fetchPagedList(
                    {
                        sortBy,
                        direction,
                    },
                    { page, pageSize },
                    searchQuery,
                    abortSignal,
                    params,
                );
                setItems(response.items);
                setPage(response.pageNumber);
                setTotal(response.total);
                setPageSize(response.pageSize);
            } catch (error) {
                setError(error as Error);
            } finally {
                setIsLoading(false);
            }
        },
        [fetchPagedList, abortSignal, setItems],
    );

    return {
        isLoading,
        error,
        items,
        page,
        total,
        onFetchItems,
        pageSize,
    };
};
