import { type ReactNode, createContext, useContext, useReducer } from 'react';
import type { PagedList } from '../../../designtime-ui/src/types';
import type { TableId as TabId, WorkflowTaskListItem } from '../types/Task';
import type { RuntimeUserAPI } from '../utils/api';

export type Props = {
    children: ReactNode;
};

export type InboxContext = {
    tasks: WorkflowTaskListItem[];
    pageNumber: number;
    pageSize: number;
    total: number;
    isLoading: boolean;
    activeTabId: TabId;
    inactiveTablePage: number;
};

export const InboxContext = createContext<InboxContext | null>(null);
export const InboxDispatchContext = createContext<React.Dispatch<InboxAction> | null>(null);

type InboxState = {
    isLoading: boolean;
    tasks: WorkflowTaskListItem[];
    pageNumber: number;
    pageSize: number;
    total: number;
    activeTabId: TabId;
    inactiveTablePage: number;
};

export const initialState: InboxState = {
    isLoading: false,
    tasks: [],
    pageNumber: 1,
    pageSize: 0,
    total: 0,
    activeTabId: 'MY_TASKS' as TabId,
    inactiveTablePage: 1,
};

export type InboxAction =
    | { type: 'reassign'; task: WorkflowTaskListItem; user: RuntimeUserAPI | null }
    | { type: 'changePage'; pageNumber: number }
    | { type: 'changeTab'; tabId: TabId }
    | { type: 'loadTasksRequest' }
    | { type: 'loadTasksSuccess'; response: PagedList<WorkflowTaskListItem> }
    | { type: 'loadTasksError'; error: Error };

export const reducer = (state: InboxState, action: InboxAction): InboxState => {
    switch (action.type) {
        case 'reassign': {
            const newTasks = [
                ...(state.tasks.find((task) => task.id === action.task.id) ? [] : [action.task]),
                ...state.tasks,
            ];

            return {
                ...state,
                tasks: newTasks
                    .map((task) => (task.id === action.task.id ? action.task : task))
                    .filter(
                        (task) => task.assigneeId === action.user?.id || task.assigneeId === null,
                    ),
            };
        }

        case 'loadTasksRequest':
            return {
                ...state,
                isLoading: true,
            };

        case 'loadTasksSuccess':
            return {
                ...state,
                isLoading: false,
                tasks: action.response.items,
                pageNumber: action.response.pageNumber,
                pageSize: action.response.pageSize,
                total: action.response.total,
            };

        case 'changeTab': {
            if (action.tabId !== state.activeTabId) {
                return {
                    ...state,
                    activeTabId: action.tabId,
                    pageNumber: state.inactiveTablePage,
                    inactiveTablePage: state.pageNumber,
                };
            }
            return state;
        }

        case 'loadTasksError':
            return {
                ...state,
                isLoading: false,
            };

        default: {
            return state;
        }
    }
};

/**
 * This provider exists to maintain the state of the inbox while the user navigates to view the full task screen.
 * */
const InboxProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <InboxContext.Provider value={state}>
            <InboxDispatchContext.Provider value={dispatch}>
                {children}
            </InboxDispatchContext.Provider>
        </InboxContext.Provider>
    );
};

const useInbox = (): InboxContext => {
    const context = useContext(InboxContext);
    if (context === null) {
        throw new Error('useInbox must be used within the InboxProvider');
    }
    return context;
};

const useInboxDispatch = (): React.Dispatch<InboxAction> => {
    const context = useContext(InboxDispatchContext);
    if (context === null) {
        throw new Error('useInboxDispatch must be used within the InboxDispatchProvider');
    }
    return context;
};

export { InboxProvider, useInbox, useInboxDispatch };
