import { useEffect, useState } from 'react';
import { useUser } from '../auth/UserProvider.tsx';
import { useInbox, useInboxDispatch, type InboxAction } from './InboxProvider.tsx';
import {
    ButtonFlavor,
    ButtonSize,
    ButtonType,
    CheckboxSize,
    ExButton,
    ExCheckbox,
    ExDropdown,
    ExIcon,
    ExInput,
    ExMenu,
    ExPagination,
    ExTab,
    ExTabItem,
    IconSize,
    IconVariant,
    PaginationType,
    SearchFlavor,
    SearchSize,
} from '@boomi/exosphere';
import Table from './Table.tsx';
import type {
    GetTasksQueryParamsType,
    TableId as TabId,
    WorkflowTaskListItem,
} from '../types/Task';
import { useNavigate } from 'react-router-dom';
import { flushSync } from 'react-dom';
import { assignTaskToMe, getTasks } from '../utils/api.ts';

import './inbox.css';
import { useSocket } from '../../../common/socket/SocketProvider.tsx';

const SORT_OPTIONS = ['Workflow', 'Step', 'Assigned To'];

const MY_TASKS_COLUMNS = [
    { label: SORT_OPTIONS[0], canSort: true },
    { label: SORT_OPTIONS[1], canSort: true },
    { label: SORT_OPTIONS[2], canSort: true },
    { label: '', canSort: false },
];

const WATCHING_COLUMNS = [
    { label: SORT_OPTIONS[0], canSort: true },
    { label: SORT_OPTIONS[1], canSort: true },
    { label: 'Status', canSort: false },
    { label: SORT_OPTIONS[2], canSort: true },
    { label: '', canSort: false },
];

const loadTasks = async (
    workspaceId: string,
    params: GetTasksQueryParamsType,
    dispatchInbox: React.Dispatch<InboxAction>,
) => {
    dispatchInbox({
        type: 'loadTasksRequest',
    });

    try {
        const response = await getTasks(workspaceId, params);

        dispatchInbox({
            type: 'loadTasksSuccess',
            response,
        });
    } catch (error) {
        console.error(error);
        dispatchInbox({
            type: 'loadTasksError',
            error: error as Error,
        });
    }
};

const Inbox = () => {
    const user = useUser();
    const { tasks, total, pageNumber, pageSize, activeTabId } = useInbox();
    const navigate = useNavigate();

    const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string | null>(null);
    const [sortBy, setSortBy] = useState<string>(SORT_OPTIONS[2]);
    const [sortAsc, setSortAsc] = useState<boolean>(false);
    const [showDone, setShowDone] = useState<boolean>(false);

    const socket = useSocket();
    const dispatchInbox = useInboxDispatch();

    useEffect(() => {
        /**
         * TODO: Remove hardcoded ID
         */
        loadTasks('0f899e17-31f2-442f-8f88-be744a1a01f0', { pageNumber: '1' }, dispatchInbox);
    }, [dispatchInbox]);

    useEffect(() => {
        const onTaskReAssigned = (task: WorkflowTaskListItem) =>
            dispatchInbox({ type: 'reassign', task, user });

        if (socket) {
            socket.on('taskreassigned', onTaskReAssigned);
        }

        return () => {
            if (socket) {
                socket.off('taskreassigned', onTaskReAssigned);
            }
        };
    }, [socket, user, dispatchInbox]);

    const rowSelectionProps = (task: WorkflowTaskListItem) => ({
        // Task must be selected and open to appear selected
        // This fixes undefined being shown while the drawer is closing
        className: selectedTaskId === task.id && isOpen ? 'selected-task' : '',
        onClick: () => {
            setSelectedTaskId(task.id);
            setIsOpen(true);
        },
    });

    const applyFilters = (tasks: WorkflowTaskListItem[]) => {
        let filteredTasks = tasks
            // apply search
            .filter(
                (task) =>
                    search === null ||
                    task.workflowName.toLowerCase().includes(search.toLowerCase()),
            )
            // apply "show done" only when viewing the WATCHING table
            .filter((task) =>
                activeTabId === 'WATCHING' ? showDone || task.status !== 'Done' : true,
            )
            // apply sort
            .sort((a, b) => {
                switch (sortBy) {
                    case SORT_OPTIONS[0]:
                        return a.workflowName.localeCompare(b.workflowName);
                    case SORT_OPTIONS[1]:
                        return a.currentStepName.localeCompare(b.currentStepName);
                    case SORT_OPTIONS[3]: {
                        // Substitute the "Assign to me" buttons with a '_button' string,
                        // so that we can still sort by name and have the buttons appear
                        // at the top or the bottom of the sort order.
                        const one = a.assigneeName || '_button';
                        const two = b.assigneeName || '_button';
                        return one.localeCompare(two);
                    }
                    default:
                        return 0; // if all else fails assume the elements are equal
                }
            });

        if (!sortAsc) {
            filteredTasks = filteredTasks.reverse();
        }

        return filteredTasks;
    };

    const handleAssign = async (workspaceId: string, taskId: string) => {
        try {
            await assignTaskToMe(workspaceId, taskId);
            loadTasks(workspaceId, { pageNumber: pageNumber.toString() }, dispatchInbox);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChangePage = (newPageNumber: number) => {
        pageNumber !== newPageNumber && dispatchInbox({ type: 'changePage', pageNumber });
    };

    const handleChangeTab = (tabId: TabId) => {
        dispatchInbox({ type: 'changeTab', tabId });
    };

    const myTasks = applyFilters(tasks);

    const watchingTasks = applyFilters(tasks);

    return (
        <>
            {/* INBOX PAGE HEADER */}
            <header className="inbox-header">
                <div className="company-logo">
                    {/* temporary company logo */}
                    <ExIcon
                        icon="community"
                        variant={IconVariant.DEFAULT}
                        size={IconSize.M}
                        hideBrowserTooltip
                    />
                    Company Name
                </div>
                <div className="notifications">
                    <ExDropdown
                        className="notifications-dropdown"
                        icon="Notification"
                        text=""
                        flavor={ButtonFlavor.BRANDED}
                        size={ButtonSize.DEFAULT}
                        type={ButtonType.TERTIARY}
                    >
                        <ExMenu>{[]}</ExMenu>
                    </ExDropdown>
                    <span className="notifications-counter">1</span>
                </div>
            </header>

            <section className="inbox-greeting">
                <h1 className="greeting text-gradient-fill">
                    Hello, {user?.firstName} {user?.lastName}
                </h1>
            </section>

            {/* TABLE SELECTION TABS */}
            <section className="inbox-tabs">
                <ExTab
                    onSelect={(e) => {
                        handleChangeTab(e.detail.selectedIndex === 0 ? 'MY_TASKS' : 'WATCHING');
                    }}
                >
                    <ExTabItem selected={activeTabId === 'MY_TASKS'}>
                        <span className="tab-item">
                            <ExIcon icon="Stack" size={IconSize.S} />
                            My Tasks
                        </span>
                    </ExTabItem>
                    <ExTabItem selected={activeTabId === 'WATCHING'}>
                        <span className="tab-item">
                            <ExIcon icon="Show" size={IconSize.S} />
                            Watching
                        </span>
                    </ExTabItem>
                </ExTab>
            </section>

            <section className="inbox-content">
                {/* TABLE FILTERS */}
                <section className="table-filters">
                    <ExInput
                        data-testId="tableFiltersSearch"
                        className="filter-search"
                        size={SearchSize.LARGE}
                        flavor={SearchFlavor.WHITE}
                        placeholder="Search Table..."
                        type="text"
                        leadingIcon="Search"
                        clearable={true}
                        removeMarginBottom={true}
                        onInput={(e: Event) => setSearch((e.target as HTMLInputElement).value)}
                    />
                    {activeTabId === 'WATCHING' && (
                        <ExCheckbox
                            data-testId="tableFiltersShowDone"
                            className="filter-checkbox"
                            checked={showDone}
                            onChange={() => setShowDone(!showDone)}
                            size={CheckboxSize.LARGE}
                        >
                            <strong>Show Done</strong>
                        </ExCheckbox>
                    )}
                </section>

                {/* MY TASKS TABLE */}
                {activeTabId === 'MY_TASKS' && (
                    <Table
                        columnData={MY_TASKS_COLUMNS}
                        setSortBy={setSortBy}
                        setSortAsc={setSortAsc}
                        sortBy={sortBy}
                        sortAsc={sortAsc}
                    >
                        {myTasks.map((task) => (
                            <tr key={task.id} {...rowSelectionProps(task)}>
                                <td>{task.workflowName}</td>
                                <td>{task.currentStepName}</td>
                                <td>
                                    {task.assigneeId ? (
                                        task.assigneeName
                                    ) : (
                                        <ExButton
                                            flavor={ButtonFlavor.BASE}
                                            type={ButtonType.SECONDARY}
                                            className="table-button"
                                            onClick={() => {
                                                handleAssign(task.workspaceId, task.id);
                                            }}
                                        >
                                            Assign to me
                                        </ExButton>
                                    )}
                                </td>
                                <td>
                                    <ExButton
                                        flavor={ButtonFlavor.BASE}
                                        type={ButtonType.SECONDARY}
                                        className="table-button"
                                        onClick={() => {
                                            if (document.startViewTransition) {
                                                document.startViewTransition(() => {
                                                    flushSync(() => {
                                                        navigate(
                                                            `task/${task.workspaceId}/${task.id}`,
                                                        );
                                                    });
                                                });
                                            } else {
                                                flushSync(() => {
                                                    navigate(`task/${task.workspaceId}/${task.id}`);
                                                });
                                            }
                                        }}
                                    >
                                        View
                                    </ExButton>
                                </td>
                            </tr>
                        ))}
                    </Table>
                )}

                {/* WATCHING TABLE */}
                {activeTabId === 'WATCHING' && (
                    <Table
                        columnData={WATCHING_COLUMNS}
                        setSortBy={setSortBy}
                        setSortAsc={setSortAsc}
                        sortBy={sortBy}
                        sortAsc={sortAsc}
                    >
                        {watchingTasks.map((task) => (
                            <tr key={task.id} {...rowSelectionProps(task)}>
                                <td>{task.workflowName}</td>
                                <td>{task.currentStepName}</td>
                                <td>
                                    <div
                                        className={`status-badge ${task.status === 'Done' ? 'done' : 'in-progress'}`}
                                    >
                                        <ExIcon
                                            icon={
                                                task.status === 'Done' ? 'Success' : 'In progress'
                                            }
                                            variant={IconVariant.ICON}
                                            size={IconSize.S}
                                        />
                                        {task.status}
                                    </div>
                                </td>
                                <td>
                                    {task.assigneeId ? (
                                        task.assigneeName
                                    ) : (
                                        <strong>Unassigned</strong>
                                    )}
                                </td>
                                <td>
                                    <ExButton
                                        flavor={ButtonFlavor.BASE}
                                        type={ButtonType.SECONDARY}
                                        className="table-button"
                                        onClick={() => {
                                            if (document.startViewTransition) {
                                                document.startViewTransition(() => {
                                                    flushSync(() => {
                                                        navigate(`task/${task.id}`);
                                                    });
                                                });
                                            } else {
                                                flushSync(() => {
                                                    navigate(`task/${task.id}`);
                                                });
                                            }
                                        }}
                                    >
                                        View
                                    </ExButton>
                                </td>
                            </tr>
                        ))}
                    </Table>
                )}

                {/* TABLE PAGINATION */}
                <ExPagination
                    className="table-pagination"
                    totalItems={total}
                    pageSize={pageSize}
                    selectedPage={pageNumber}
                    type={PaginationType.DEFAULT}
                    hideControls={true}
                    onChange={(e: CustomEvent) => {
                        handleChangePage(e.detail.selectedPage);
                    }}
                />
            </section>
        </>
    );
};

export default Inbox;
