import { ButtonFlavor, ButtonType, ExButton, ExTextarea } from '@boomi/exosphere';
import { useState } from 'react';
import type { CommentApi } from '../types/Task.ts';
import './comment-box.css';

interface Props {
    onCommentAdded: (comment: CommentApi) => void;
}
const CommentBox = ({ onCommentAdded }: Props) => {
    const [content, setContent] = useState('');

    const onAddComment = () => {
        onCommentAdded({
            id: crypto.randomUUID(),
            version: 0,
            runtimeUser: null,
            content: content,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        });
    };

    return (
        <div className="comment-section">
            <h4>Comment</h4>
            <div className="comment-box">
                <ExTextarea
                    value={content}
                    rows={5}
                    resize="none"
                    onChange={(e: CustomEvent) =>
                        setContent((e.target as HTMLTextAreaElement).value)
                    }
                />
                <ExButton
                    className="comment-submit"
                    onClick={() => {
                        onAddComment();
                        setContent('');
                    }}
                    flavor={ButtonFlavor.BASE}
                    type={ButtonType.SECONDARY}
                >
                    Send
                </ExButton>
            </div>
        </div>
    );
};

export default CommentBox;
